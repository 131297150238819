/* eslint-disable react/prop-types */
/* ========================================================= * Material Kit 2 PRO React - v2.0.0 ========================================================= * Product Page: https://www.creative-tim.com/product/material-kit-pro-react * Copyright 2021 Creative Tim (https://www.creative-tim.com) Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */

// next
import dynamic from 'next/dynamic';
import Image from 'next/image';

// MUI
const { Link: MuiLink } = dynamic(() => import('@mui/material/Link'), { loading: () => null });

// PRO
const MDBox = dynamic(() => import('components/MDBox'), { loading: () => null });
const MDButton = dynamic(() => import('components/MDButton'), { loading: () => null });
const MDTypography = dynamic(() => import('components/MDTypography'), { loading: () => null });

function RotatingCard({
  color, image, title, description, action,
}) {
  return (
    <>
      <Image
        style={{
          zIndex: 6, borderRadius: '0.5rem', transform: 'rotateY(180deg)', backfaceVisibility: 'hidden', objectFit: 'cover', objectPosition: 'cover',
        }}
        src={image}
        alt="title"
        fill
      />
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius="lg"
        coloredShadow={color}
        position="absolute"
        width="100%"
        height="100%"
        top={0}
        left={0}
        zIndex={7}
        sx={{
          backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) => `${linearGradient(
            rgba(gradients[color] ? gradients[color].main : gradients.info.main, 0.85),
            rgba(gradients[color] ? gradients[color].main : gradients.info.main, 0.85),
          )}`,
          backgroundSize: 'cover',
          backfaceVisibility: 'hidden',
          transform: 'rotateY(180deg)',
        }}
      >
        <MDBox pt={12} pb={2} px={2} textAlign="center" lineHeight={1}>
          <MDTypography variant="h3" color="white" gutterBottom>
            {title}
          </MDTypography>
          <MDTypography variant="body2" color="white" opacity={0.8} fontWeight="regular">
            {description}
          </MDTypography>
          {action && (
            <MDBox width="50%" mt={4} mb={2} mx="auto">
              {action.type === 'external' ? (
                <MDButton
                  component={MuiLink}
                  href={action.route}
                  target="_blank"
                  rel="noreferrer"
                  color="white"
                  size="small"
                  fullWidth
                >
                  {action.label}
                </MDButton>
              ) : (
                <MDButton component={MuiLink} href={action.route} color="white" size="small" fullWidth>
                  {action.label}
                </MDButton>
              )}
            </MDBox>
          )}
        </MDBox>
      </MDBox>
    </>
  );
}

export default RotatingCard;
